/*.categories .progress {*/
/*    height: 6px;*/
/*}*/

/*.categories .progress-bar.a {*/
/*    width: 85%;*/
/*}*/

/*.categories .progress-bar.b {*/
/*    width: 20%;*/
/*}*/

/*.categories .progress-bar.c {*/
/*    width: 60%;*/
/*}*/

/*.categories .progress-bar.d {*/
/*    width: 80%;*/
/*}*/


/*#menu > li:hover > a::after {*/
/*    transform: rotate(225deg) translateY(-50%) !important;*/
/*}*/

/*#menu > li > ul {*/
/*    display: none;*/
/*}*/

/*#menu > li:hover > ul {*/
/*    display: block;*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    min-width: 100%;*/
/*    z-index: 1001;*/
/*    background: white;*/
/*    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);*/
/*}*/

.form {
}

.form input, .form select {
    height: 45px;
}

.form label {
    font-weight: 500;
}

.form .btnSection {
    text-align: left;
}

/*.form .btnSection .btn {*/
/*}*/


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

/*.unValidateElement {*/
/*    color: #dc3545;*/
/*}*/

/*.chat-header, .chat-footer {*/
/*    right: 0;*/
/*}*/

/*.chat-content {*/
/*    margin-right: 0;*/
/*}*/

.uploadFileBox {
}

h1.memberTitle {
    font-size: 15px;
    font-weight: 800;
}

.uploadFileBox i {
    z-index: 0;
    cursor: pointer;
    width: 37px;
    height: 37px;
    border: .5px solid #ddd;
    text-align: center;
    font-size: 22px;
    border-radius: 22px;
}

.uploadFileBox input {
    position: absolute;
    left: 13px;
    width: 40px;
    padding: 4px;
    margin-top: 2px;
    border-radius: 30px;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
}

/*.chat-footer button {*/

/*}*/

/*.chat-content {*/
/*    !*overflow-x: hidden !important;*!*/
/*    !*overflow-y: hidden !important;*!*/
/*    !*max-width: 1350px;*!*/
/*}*/

/*.chat-content .content {*/
/*}*/

/*.chat-content i.user {*/
/*    font-size: 30px;*/
/*}*/

/*.chat-content .chat-content-leftside i.user {*/
/*}*/

/*.chat-content .chat-content-rightside i.user {*/
/*}*/

/*.chat-content .chat-content-leftside .content {*/
/*}*/

/*.chat-content .chat-content-rightside .content {*/
/*    flex-direction: row-reverse;*/
/*}*/

/*.chat-content p.userName {*/
/*}*/

/*.chat-content p.replay {*/
/*}*/


/*#forumComponent .chat-content-leftside {*/
/*    text-align: left;*/
/*    min-height: 120px;*/
/*    direction: ltr;*/
/*}*/

/*#forumComponent .chat-content-leftside i.user {*/
/*    float: left;*/
/*}*/

/*#forumComponent .chat-content-leftside .content {*/
/*}*/

/*#forumComponent .chat-content-leftside p.userName {*/
/*    position: relative;*/
/*    top: 13px;*/
/*    right: -10px;*/
/*    margin: 0;*/
/*    float: left;*/
/*}*/

/*#forumComponent .chat-content-leftside .chat-time {*/
/*    text-align: left !important;*/
/*    position: relative;*/
/*    top: 13px;*/
/*    left: 38px;*/
/*    direction: rtl;*/
/*}*/

/*#forumComponent .chat-content-leftside .chat-left-msg {*/
/*    border-radius: 0 12px 12px 12px;*/
/*    margin-top: 25px;*/
/*    float: unset;*/
/*}*/


/*#forumComponent .chat-content-rightside {*/
/*    text-align: right;*/
/*    min-height: 120px;*/
/*}*/

/*.chat-content-leftside .chat-left-msg {*/
/*    margin-bottom: 40px !important;*/
/*}*/

/*.chat-content-rightside .chat-right-msg {*/
/*    margin-bottom: 50px !important;*/
/*}*/

/*.chat-content-leftside a {*/
/*    position: relative;*/
/*    top: -43px;*/
/*    font-size: 11px;*/
/*    font-weight: 600;*/
/*    color: #c9c9c9;*/
/*}*/

/*.chat-content-rightside a {*/
/*    position: absolute;*/
/*    left: 4%;*/
/*    margin-top: 4%;*/
/*    font-size: 11px;*/
/*    font-weight: 600;*/
/*    color: #c9c9c9;*/
/*}*/

/*#forumComponent .chat-content-rightside i.user {*/
/*    float: right;*/
/*}*/

/*#forumComponent .chat-content-rightside .content {*/
/*}*/

/*#forumComponent .chat-content-rightside p.userName {*/
/*    position: relative;*/
/*    top: 13px;*/
/*    right: 10px;*/
/*    margin: 0;*/
/*    float: right;*/
/*}*/

/*#forumComponent .chat-content-rightside .chat-time {*/
/*    text-align: right !important;*/
/*    position: relative;*/
/*    top: 13px;*/
/*    right: 38px;*/
/*}*/

/*#forumComponent .chat-content-rightside .chat-right-msg {*/
/*    border-radius: 12px 0 12px 12px;*/
/*    margin-top: 25px;*/
/*    float: unset;*/
/*}*/

/*#formPage {*/
/*}*/

/*#formPage .card-img-top {*/
/*    width: 100%;*/
/*    height: 200px;*/
/*}*/

/*#formPage .card-title {*/
/*    text-align: center;*/
/*    margin-bottom: 15px;*/
/*}*/

/*#formPage .card-text {*/
/*    height: 100px;*/
/*    line-height: 2;*/
/*    text-align: justify;*/
/*}*/

/*#formPage .btn {*/
/*}*/

/*.encyclopediaPage img {*/
/*    width: 30% !important;*/
/*    margin: 30px auto;*/
/*    height: 120px !important;*/
/*    border-radius: 100%;*/
/*}*/

.encyclopediaPage .score {
    color: #31e067;
    font-weight: 900;
    position: absolute;
    margin-right: 20px;
    margin-top: 15px;
    font-size: 18px;
}

/*#scoreHistoryPage {*/
/*}*/

/*#scoreHistoryPage .box {*/
/*    cursor: pointer;*/
/*}*/

/*#scoreHistoryPage img {*/
/*    width: 50% !important;*/
/*    margin: 30px auto;*/
/*    height: 100px !important;*/
/*}*/

/*#scoreHistoryPage .card-text {*/
/*    font-weight: 900;*/
/*}*/

/*#scoreHistoryPage .date {*/
/*    font-size: 17px;*/
/*    margin-top: 20px;*/
/*    text-align: left;*/
/*}*/

/*#scoreHistoryPage .posetive {*/
/*    color: #31e067;*/
/*    font-weight: 900;*/
/*    text-align: center;*/
/*}*/

/*#scoreHistoryPage .negative {*/
/*    color: #cd280b;*/
/*    font-weight: 900;*/
/*    text-align: center;*/
/*}*/

/*.btn1 {*/
/*    padding: 10px;*/
/*    text-align: center;*/
/*    display: table;*/
/*    text-decoration: none !important;*/
/*    margin: 0 auto 0 0;*/
/*    color: #fff;*/
/*    background-color: #31b8e0;*/
/*    border: .5px solid #31b8e0;*/

/*    -webkit-transition: all 0.3s ease-in-out;*/
/*    -moz-transition: all 0.3s ease-in-out;*/
/*    -ms-transition: all 0.3s ease-in-out;*/
/*    -o-transition: all 0.3s ease-in-out;*/
/*    transition: all 0.3s ease-in-out;*/
/*}*/

/*.btn1:hover {*/
/*    color: #31b8e0;*/
/*    background-color: #fff;*/
/*}*/


/*#formDetailPage {*/
/*}*/

/*#formDetailPage .selectBox,*/
/*#formDetailPage .inputBox {*/
/*    display: none !important;*/
/*}*/


/*.goBackLink {*/
/*    position: absolute;*/
/*    left: 6%;*/
/*    margin-top: -2%;*/
/*    border: .5px solid #ddd;*/
/*}*/

/*.p-10-15-10-40 {*/
/*    padding: 10px 15px 10px 40px !important;*/
/*}*/


/*.awardPage {*/
/*}*/

/*.awardPage .item {*/
/*    text-align: center;*/
/*}*/

/*.awardPage .item h4 {*/
/*    color: #19caca;*/
/*    font-weight: 100;*/
/*    font-size: 15px;*/
/*    margin-top: 15px;*/
/*}*/

/*.awardPage .item h5, .charityPage h5 {*/
/*    font-size: 18px;*/
/*    font-weight: 500;*/
/*    color: #b9b8b8;*/
/*}*/

/*.charityPage h5 {*/
/*    text-align: center;*/
/*}*/

/*.awardPage .item img, .charityPage img {*/
/*    margin: 25px 25%;*/
/*    width: 50%;*/
/*    height: 145px;*/
/*    border-radius: 100%;*/
/*}*/

/*#awardDetailPage {*/
/*}*/

/*#awardDetailPage img {*/
/*    width: 200px;*/
/*    height: 180px;*/
/*    border-radius: 100%;*/
/*    margin: 30px auto;*/
/*}*/

/*#awardDetailPage h1 {*/
/*    font-size: 20px;*/
/*    font-weight: 900;*/
/*    text-align: center;*/
/*}*/

/*#awardDetailPage h2, #awardDetailPage h3, #charityDetailPage h2, #charityDetailPage h3 {*/
/*    font-size: 18px;*/
/*    font-weight: 500;*/
/*}*/

/*#awardDetailPage b, #charityDetailPage b {*/
/*    color: #33e3ff;*/
/*}*/

/*#awardDetailPage p {*/
/*}*/


/*.top-header .navbar .dropdown-item .setting-icon {*/
/*    width: 33px;*/
/*    height: 33px;*/
/*}*/

/*.top-header .navbar .dropdown-item .setting-text {*/
/*    font-size: 14px;*/
/*}*/


.table2 {
}

.table2 tr {
}

.table2 tr th {
    text-align: center;
}

.table2 tr td {
    text-align: center
}


/*.logo-text {*/
/*    font-size: 15px;*/
/*    letter-spacing: 0px;*/
/*    font-weight: 700;*/
/*}*/

/*.topbar-nav .metismenu a {*/
/*    font-size: 14px;*/
/*    letter-spacing: 0;*/
/*    font-weight: 700;*/
/*}*/

/*a.pcalBtn {*/
/*    margin-top: -42px;*/
/*    margin-right: 3px;*/
/*    border-radius: 5px;*/
/*}*/

input.dateElement {
    text-align: left;
}

.displayNone {
    display: none !important;
}

.pointer {
    cursor: pointer !important;
}

.btnSection{
    text-align: left;
}

/*.form-switch{}*/
/*.form-switch label{*/
/*    position: relative;*/
/*    top: 7px;*/
/*    right: 10px;*/
/*}*/
/*.form-switch input{*/
/*    width: 40px !important;*/
/*    height: 23px !important;*/
/*}*/

/*@media screen and (max-width: 1280px) {*/
/*    .wrapper.toggled .mobile-topbar-header {*/
/*        padding: 0px 10px;*/
/*    }*/
/*}*/

.forms-date .JDatePicker {
    width: 265px !important;
}
